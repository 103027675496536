@font-face {
  font-family: 'Proto Grotesk';
  font-weight: 400;
  src: local('Proto Grotesk'), url(./assets/fonts/ProtoGrotesk-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Forever Grotesk';
  font-weight: 100;
  src: local('Forever Grotesk'), url(./assets/fonts/NNForeverGroteskSTD-Light.otf) format('opentype');
}

body {
  margin: 0;
  font-family: 'Barlow', 'Proto Grotesk', 'Forever Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: linear-gradient(90deg, rgba(10,2,36,1) 0%, rgba(21,26,55,1) 100%);*/
  width: 100%;
  height: 100%;
  max-height: 100vh;
  min-height: -webkit-fill-available;
  -webkit-user-select: none;
  user-select: none;
}

html {
  height: -webkit-fill-available;
}

.vote {
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.particle {
  position: absolute;
  will-change: transform;
  overflow: hidden;
}
